// import React, { useState } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';
// import { db, auth } from './firebase';
// import { collection, addDoc } from 'firebase/firestore';
// import { createUserWithEmailAndPassword } from 'firebase/auth';

// const AddWebUserModal = ({ onAddUser }) => {
//   const [show, setShow] = useState(false);
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [role, setRole] = useState('User');
//   const [password, setPassword] = useState('');
//   const [status, setStatus] = useState('Active');
//   const [loading, setLoading] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (password.length < 6) {
//       alert('Password must be at least 6 characters');
//       return;
//     }

//     try {
//       setLoading(true);

//       // Create user in Firebase Authentication
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       const user = userCredential.user;

//       // Add additional user data to Firestore (without storing the password)
//       const docRef = await addDoc(collection(db, 'webUsers'), {
//         uid: user.uid, // Store the user ID from Firebase Auth
//         name,
//         email,
//         phone,
//         role,
//         password,
//         status,
//       });

//       console.log('User added with ID: ', docRef.id);

//       // Optionally pass the user data to the parent component
//       onAddUser({ name, email, phone, role, status });

//       handleClose();
//     } catch (error) {
//       console.error('Error adding user: ', error);
//       alert('Failed to add user');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <Button variant="primary" onClick={handleShow}>
//         Add New User
//       </Button>

//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add Web User</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleSubmit}>
//             <Form.Group controlId="formName">
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 placeholder="Enter name"
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="formEmail">
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 placeholder="Enter email"
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="formPhone">
//               <Form.Label>Phone</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={phone}
//                 onChange={(e) => setPhone(e.target.value)}
//                 placeholder="Enter phone"
//               />
//             </Form.Group>

//             <Form.Group controlId="formRole">
//               <Form.Label>Role</Form.Label>
//               <Form.Control
//                 as="select"
//                 value={role}
//                 onChange={(e) => setRole(e.target.value)}
//                 required
//               >
//                 <option>Admin</option>
//                 <option>User</option>
//                 <option>Blog Writer</option>
//                 <option>Payment Checker</option>
//               </Form.Control>
//             </Form.Group>

//             <Form.Group controlId="formPassword">
//               <Form.Label>Password</Form.Label>
//               <Form.Control
//                 type="password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 placeholder="Enter password"
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="formStatus">
//               <Form.Label>Status</Form.Label>
//               <Form.Control
//                 as="select"
//                 value={status}
//                 onChange={(e) => setStatus(e.target.value)}
//               >
//                 <option value="Active">Active</option>
//                 <option value="Inactive">Inactive</option>
//               </Form.Control>
//             </Form.Group>

//             <Button variant="primary" type="submit" disabled={loading}>
//               {loading ? 'Adding User...' : 'Add User'}
//             </Button>
//           </Form>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default AddWebUserModal;



import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { db, auth } from './firebase';
import { collection, addDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';

const AddWebUserModal = ({ onAddUser }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [experience, setExperience] = useState('');
  const [field, setField] = useState(''); // New Field State
  const [role, setRole] = useState('User');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('Active');
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 6) {
      alert('Password must be at least 6 characters');
      return;
    }

    // Simple email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert('Invalid email format');
      return;
    }

    try {
      setLoading(true);

      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add additional user data to Firestore (without storing the password)
      const docRef = await addDoc(collection(db, 'webUsers'), {
        uid: user.uid, // Store the user ID from Firebase Auth
        name,
        email,
        phone,
        address,
        experience,
        field, // Add field to Firestore
        role,
        status,
      });

      console.log('User added with ID: ', docRef.id);

      // Pass the user data to the parent component
      onAddUser({ name, email, phone, address, experience, field, role, status });

      // Reset form fields
      setName('');
      setEmail('');
      setPhone('');
      setAddress('');
      setExperience('');
      setField(''); // Reset field
      setRole('User');
      setPassword('');
      setStatus('Active');

      handleClose();
    } catch (error) {
      console.error('Error adding user: ', error);
      alert('Failed to add user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add New User
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Web User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter phone"
              />
            </Form.Group>

            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter address"
              />
            </Form.Group>

            <Form.Group controlId="formExperience">
              <Form.Label>Experience</Form.Label>
              <Form.Control
                type="text"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                placeholder="Enter experience"
              />
            </Form.Group>

            <Form.Group controlId="formField">
              <Form.Label>Field</Form.Label>
              <Form.Control
                as="select"
                value={field}
                onChange={(e) => setField(e.target.value)}
                required
              >
                <option value="">Select Field</option>
                <option value="Technology">Technology</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Finance">Finance</option>
                <option value="Education">Education</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              >
                <option>Admin</option>
                <option>User</option>
                <option>Blog Writer</option>
                <option>Payment Checker</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                required
              />
            </Form.Group>

            <Form.Group controlId="formStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Adding User...' : 'Add User'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddWebUserModal;
