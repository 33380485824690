// import React, { useEffect, useState } from 'react';
// import { Button, Table, Modal, Form } from 'react-bootstrap';
// // //import {
// // , getDocs, doc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';
// import { db } from './firebase'; // Adjust the path as needed

// import { collection, getDocs, doc, updateDoc,  addDoc } from 'firebase/firestore';

// const SetupDataManager = () => {
//   const [setupData, setSetupData] = useState([]);
//   const [selectedData, setSelectedData] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [eventBar, setEventBar] = useState('');
//   const [contactUs, setContactUs] = useState('');
//   const [partnersSponsors, setPartnersSponsors] = useState('');
//   const [visitWebsite, setVisitWebsite] = useState('');
//   const [status, setStatus] = useState('Active');

//   useEffect(() => {
//     const fetchData = async () => {
//       const querySnapshot = await getDocs(collection(db, 'setup_data'));
//       setSetupData(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
//     };

//     fetchData();
//   }, []);

//   const handleEdit = (data) => {
//     setSelectedData(data);
//     setEventBar(data.eventBar || '');
//     setContactUs(data.contactUs || '');
//     setPartnersSponsors(data.partnersSponsors || '');
//     setVisitWebsite(data.visitWebsite || '');
//     setStatus(data.status || 'Active');
//     setEditMode(true);
//     setShowModal(true);
//   };

//   // const handleAdd = () => {
//   //   setSelectedData({
//   //     eventBar: '',
//   //     contactUs: '',
//   //     partnersSponsors: '',
//   //     visitWebsite: '',
//   //     status: 'Active'
//   //   });
//   //   setEventBar('');
//   //   setContactUs('');
//   //   setPartnersSponsors('');
//   //   setVisitWebsite('');
//   //   setStatus('Active');
//   //   setEditMode(false);
//   //   setShowModal(true);
//   // };

//   // const handleDelete = async (id) => {
//   //   await deleteDoc(doc(db, 'setup_data', id));
//   //   setSetupData(setupData.filter(data => data.id !== id));
//   // };

//   const handleToggleStatus = async (data) => {
//     const newStatus = data.status === 'Active' ? 'Disabled' : 'Active';
//     const dataRef = doc(db, 'setup_data', data.id);
//     await updateDoc(dataRef, { status: newStatus });

//     // Refresh the data list
//     const querySnapshot = await getDocs(collection(db, 'setup_data'));
//     setSetupData(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
//   };

//   const handleSave = async (e) => {
//     e.preventDefault();
//     const newData = {
//       eventBar,
//       contactUs,
//       partnersSponsors,
//       visitWebsite,
//       status
//     };

//     if (editMode) {
//       const dataRef = doc(db, 'setup_data', selectedData.id);
//       await updateDoc(dataRef, newData);
//     } else {
//       await addDoc(collection(db, 'setup_data'), newData);
//     }

//     const querySnapshot = await getDocs(collection(db, 'setup_data'));
//     setSetupData(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
//     setShowModal(false);
//     setSelectedData(null);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     if (name === 'eventBar') setEventBar(value);
//     if (name === 'contactUs') setContactUs(value);
//     if (name === 'partnersSponsors') setPartnersSponsors(value);
//     if (name === 'visitWebsite') setVisitWebsite(value);
//     if (name === 'status') setStatus(value);
//   };

//   return (
//     <div>

//       <h3 className="text-center">Setup Data Manager</h3>
//       {/* <Button variant="primary" onClick={handleAdd} className="mb-3">Add New Setup Data</Button> */}
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th>Event Bar</th>
//             <th>Contact Us</th>
//             <th>Partners/Sponsors</th>
//             <th>Visit Website</th>
//             <th>Status</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {setupData.map(data => (
//             <tr key={data.id}>
//               <td>{data.eventBar}</td>
//               <td>{data.contactUs}</td>
//               <td>{data.partnersSponsors}</td>
//               <td>{data.visitWebsite}</td>
//               <td>{data.status}</td>
//               <td>
//                 <Button variant="warning" onClick={() => handleEdit(data)}>Edit</Button>
//                 {data.status === 'Active' && (
//                   <>
//                     {/* <Button variant="danger" onClick={() => handleDelete(data.id)} className="ms-2">Delete</Button>
//                     <Button
//                       variant="secondary"
//                       onClick={() => handleToggleStatus(data)}
//                       className="ms-2"
//                     >
//                       Disable
//                     </Button> */}
//                   </>
//                 )}
//                 {data.status === 'Disabled' && (
//                   <Button
//                     variant="success"
//                     onClick={() => handleToggleStatus(data)}
//                     className="ms-2"
//                   >
//                     Enable
//                   </Button>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>{editMode ? 'Edit Setup Data' : 'Add Setup Data'}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleSave}>
//             <Form.Group className="mb-3" controlId="formEventBar">
//               <Form.Label>Event Bar</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="eventBar"
//                 value={eventBar}
//                 onChange={handleInputChange}
//                 placeholder="Enter event bar"
//               />
//             </Form.Group>

//             <Form.Group className="mb-3" controlId="formContactUs">
//               <Form.Label>Contact Us</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="contactUs"
//                 value={contactUs}
//                 onChange={handleInputChange}
//                 placeholder="Enter contact us info"
//               />
//             </Form.Group>

//             <Form.Group className="mb-3" controlId="formPartnersSponsors">
//               <Form.Label>Partners/Sponsors</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partnersSponsors"
//                 value={partnersSponsors}
//                 onChange={handleInputChange}
//                 placeholder="Enter partners/sponsors"
//               />
//             </Form.Group>

//             <Form.Group className="mb-3" controlId="formVisitWebsite">
//               <Form.Label>Visit Website</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="visitWebsite"
//                 value={visitWebsite}
//                 onChange={handleInputChange}
//                 placeholder="Enter website URL"
//               />
//             </Form.Group>

//             <Form.Group className="mb-3" controlId="formStatus">
//               <Form.Label>Status</Form.Label>
//               <Form.Select
//                 name="status"
//                 value={status}
//                 onChange={handleInputChange}
//               >
//                 <option value="Active">Active</option>
//                 <option value="Disabled">Disabled</option>
//               </Form.Select>
//             </Form.Group>

//             <Button variant="primary" type="submit">
//               Save
//             </Button>
//           </Form>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// };

// export default SetupDataManager;

// import React, { useEffect, useState } from "react";
// import { Button, Table, Modal, Form } from "react-bootstrap";
// import { db } from "./firebase"; // Adjust the path as needed
// import {
//   collection,
//   getDocs,
//   doc,
//   updateDoc,
//   addDoc,
// } from "firebase/firestore";

// const SetupDataManager = () => {
//   const [setupData, setSetupData] = useState([]);
//   const [selectedData, setSelectedData] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [eventBar, setEventBar] = useState("");
//   const [contactUs, setContactUs] = useState("");
//   const [partnersSponsors, setPartnersSponsors] = useState("");
//   const [visitWebsite, setVisitWebsite] = useState("");
//   const [status, setStatus] = useState("Active");
//   const [telegram, setTelegram] = useState("");
//   const [learnMore, setLearnMore] = useState("");
  


//   useEffect(() => {
//     const fetchData = async () => {
//       const querySnapshot = await getDocs(collection(db, "setup_data"));
//       setSetupData(
//         querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
//       );
//     };

//     fetchData();
//   }, []);

//   const handleEdit = (data) => {
//     setSelectedData(data);
//     setEventBar(data.eventBar || "");
//     setContactUs(data.contactUs || "");
//     setPartnersSponsors(data.partnersSponsors || "");
//     setVisitWebsite(data.visitWebsite || "");
//     setStatus(data.status || "Active");
//     setTelegram(data.telegram || "");
//     setLearnMore(data.learnMore || "");
//     setEditMode(true);
//     setShowModal(true);
//   };

//   const handleSave = async (e) => {
//     e.preventDefault();
//     const newData = {
//       eventBar,
//       contactUs,
//       partnersSponsors,
//       visitWebsite,
//       status,
//       telegram,
//       learnMore,
//       PaidBar,
//       UnpaidBar,
//       businessLeadPage,
   
//     };

//     try {
//       if (editMode) {
//         // Ensure selectedData and selectedData.id are not null
//         if (!selectedData || !selectedData.id) {
//           throw new Error("No selected data available for update.");
//         }
//         const dataRef = doc(db, "setup_data", selectedData.id);
//         await updateDoc(dataRef, newData);
//       } else {
//         await addDoc(collection(db, "setup_data"), newData);
//       }

//       const querySnapshot = await getDocs(collection(db, "setup_data"));
//       setSetupData(
//         querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
//       );
//       setShowModal(false);
//       setSelectedData(null);
//     } catch (error) {
//       console.error("Error saving data: ", error);
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "eventBar") setEventBar(value);
//     if (name === "contactUs") setContactUs(value);
//     if (name === "partnersSponsors") setPartnersSponsors(value);
//     if (name === "visitWebsite") setVisitWebsite(value);
//     // if (name === 'status') setStatus(value);
//     if (name === "telegram") setTelegram(value);
//     if (name === "learnMore") setLearnMore(value);
//   };

//   const handleToggleStatus = async (data) => {
//     const newStatus = data.status === "Active" ? "Disabled" : "Active";
//     const dataRef = doc(db, "setup_data", data.id);
//     await updateDoc(dataRef, { status: newStatus });

//     // Refresh the data list
//     const querySnapshot = await getDocs(collection(db, "setup_data"));
//     setSetupData(
//       querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
//     );
//   };

//   return (
//     <div>
//       <h3 className="text-center" style={{ backgroundColor: "grey" }}>
//         {" "}
//         Setup Data Manager{" "}
//       </h3>
//       <Table striped bordered hover>
//         <tbody>
//           {setupData.map((data) => (
//             <React.Fragment key={data.id}>
//               <tr>
//                 <td>
//                   <strong style={{ fontSize: "1.5rem" }}>Name </strong>
//                 </td>
//                 <td>
//                   <strong style={{ fontSize: "1.5rem" }}>Actions</strong>
//                 </td>
//               </tr>
//               <tr>
//                 <td>
//                   <strong>Paid Bar:</strong>
//                 </td>
//                 <td>{data.PaidBar}</td>
//               </tr>
//               <tr>
//                 <td>
//                   <strong>Un-paid Bar:</strong>
//                 </td>
//                 <td>{data.UnpaidBar}</td>
//               </tr>
            
//               <tr>
//                 <td>
//                   <strong>Business lead :</strong>
//                 </td>
//                 <td>{data.businessLeadPage}</td>
//               </tr>
//               <tr>
//                 <td>
//                   <strong>Event Bar:</strong>
//                 </td>
//                 <td>{data.eventBar}</td>
//               </tr>
//               <tr>
//                 <td>
//                   <strong>Contact Us:</strong>
//                 </td>
//                 <td>{data.contactUs}</td>
//               </tr>
//               <tr>
//                 <td>
//                   <strong>Partners/Sponsors:</strong>
//                 </td>
//                 <td>{data.partnersSponsors}</td>
//               </tr>
//               <tr>
//                 <td>
//                   <strong>Visit Website:</strong>
//                 </td>
//                 <td>{data.visitWebsite}</td>
//               </tr>
//               <tr>
//                 <td>
//                   <strong>Telegram:</strong>
//                 </td>
//                 <td>{data.telegram}</td>
//               </tr>
//               <tr>
//                 <td>
//                   <strong>Learn More:</strong>
//                 </td>
//                 <td>{data.learnMore}</td>
//               </tr>
//               <tr>
//                 <td>
//                   <strong>Actions:</strong>
//                 </td>
//                 <td>
//                   <Button
//                     variant="warning"
//                     onClick={() => handleEdit(data)}
//                     className="me-2"
//                   >
//                     Edit
//                   </Button>
//                   {data.status === "Disabled" && (
//                     <Button
//                       variant="success"
//                       onClick={() => handleToggleStatus(data)}
//                     >
//                       Enable
//                     </Button>
//                   )}
//                 </td>
//               </tr>
//             </React.Fragment>
//           ))}
//         </tbody>
//       </Table>
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//   <Modal.Header closeButton>
//     <Modal.Title>
//       {editMode ? "Edit Setup Data" : "Add Setup Data"}
//     </Modal.Title>
//   </Modal.Header>
//   <Modal.Body>
//     <Form onSubmit={handleSave}>
//       <Form.Group className="mb-3" controlId="formEventBar">
//         <Form.Label>Event Bar</Form.Label>
//         <Form.Control
//           type="text"
//           name="eventBar"
//           value={eventBar}
//           onChange={handleInputChange}
//           placeholder="Enter event bar"
//         />
//       </Form.Group>

//       <Form.Group className="mb-3" controlId="formContactUs">
//         <Form.Label>Contact Us</Form.Label>
//         <Form.Control
//           type="text"
//           name="contactUs"
//           value={contactUs}
//           onChange={handleInputChange}
//           placeholder="Enter contact us info"
//         />
//       </Form.Group>

//       <Form.Group className="mb-3" controlId="formPartnersSponsors">
//         <Form.Label>Partners/Sponsors</Form.Label>
//         <Form.Control
//           type="text"
//           name="partnersSponsors"
//           value={partnersSponsors}
//           onChange={handleInputChange}
//           placeholder="Enter partners/sponsors"
//         />
//       </Form.Group>

//       <Form.Group className="mb-3" controlId="formVisitWebsite">
//         <Form.Label>Visit Website</Form.Label>
//         <Form.Control
//           type="text"
//           name="visitWebsite"
//           value={visitWebsite}
//           onChange={handleInputChange}
//           placeholder="Enter website URL"
//         />
//       </Form.Group>

//       <Form.Group className="mb-3" controlId="formTelegram">
//         <Form.Label>Telegram</Form.Label>
//         <Form.Control
//           type="text"
//           name="telegram"
//           value={telegram}
//           onChange={handleInputChange}
//           placeholder="Enter Telegram handle or link"
//         />
//       </Form.Group>

//       <Form.Group className="mb-3" controlId="formLearnMore">
//         <Form.Label>Learn More</Form.Label>
//         <Form.Control
//           type="text"
//           name="learnMore"
//           value={learnMore}
//           onChange={handleInputChange}
//           placeholder="Enter Learn More link"
//         />
//       </Form.Group>

//       <Form.Group className="mb-3" controlId="formPaidBar">
//         <Form.Label>Paid Bar</Form.Label>
//         <Form.Control
//           type="text"
//           name="paidBar"
//           value={paidBar}
//           onChange={handleInputChange}
//           placeholder="Enter Paid Bar"
//         />
//       </Form.Group>

//       <Form.Group className="mb-3" controlId="formUnpaidBar">
//         <Form.Label>Unpaid Bar</Form.Label>
//         <Form.Control
//           type="text"
//           name="unpaidBar"
//           value={unpaidBar}
//           onChange={handleInputChange}
//           placeholder="Enter Unpaid Bar"
//         />
//       </Form.Group>

//       <Form.Group className="mb-3" controlId="formBusinessLeadPage">
//         <Form.Label>Business Lead Page</Form.Label>
//         <Form.Control
//           type="text"
//           name="businessLeadPage"
//           value={businessLeadPage}
//           onChange={handleInputChange}
//           placeholder="Enter Business Lead Page"
//         />
//       </Form.Group>

//       <Button variant="primary" type="submit">
//         Save
//       </Button>
//     </Form>
//   </Modal.Body>
// </Modal>

//     </div>
//   );
// };

// export default SetupDataManager;




import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Form } from "react-bootstrap";
import { db } from "./firebase"; // Adjust the path as needed
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";

const SetupDataManager = () => {
  const [setupData, setSetupData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [eventBar, setEventBar] = useState("");
  const [contactUs, setContactUs] = useState("");
  const [partnersSponsors, setPartnersSponsors] = useState("");
  const [visitWebsite, setVisitWebsite] = useState("");
  const [status, setStatus] = useState("Active");
  const [telegram, setTelegram] = useState("");
  const [learnMore, setLearnMore] = useState("");
  const [paidBar, setPaidBar] = useState("");          // Added variable
  const [unpaidBar, setUnpaidBar] = useState("");      // Added variable
  const [businessLeadPage, setBusinessLeadPage] = useState(""); // Added variable

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "setup_data"));
      setSetupData(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    fetchData();
  }, []);

  const handleEdit = (data) => {
    setSelectedData(data);
    setEventBar(data.eventBar || "");
    setContactUs(data.contactUs || "");
    setPartnersSponsors(data.partnersSponsors || "");
    setVisitWebsite(data.visitWebsite || "");
    setStatus(data.status || "Active");
    setTelegram(data.telegram || "");
    setLearnMore(data.learnMore || "");
    setPaidBar(data.paidBar || "");                 // Initialize variable
    setUnpaidBar(data.unpaidBar || "");             // Initialize variable
    setBusinessLeadPage(data.businessLeadPage || ""); // Initialize variable
    setEditMode(true);
    setShowModal(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const newData = {
      eventBar,
      contactUs,
      partnersSponsors,
      visitWebsite,
      status,
      telegram,
      learnMore,
      paidBar,                // Change to paidBar
      unpaidBar,  
      businessLeadPage,       // Add to newData object
    };

    try {
      if (editMode) {
        if (!selectedData || !selectedData.id) {
          throw new Error("No selected data available for update.");
        }
        const dataRef = doc(db, "setup_data", selectedData.id);
        await updateDoc(dataRef, newData);
      } else {
        await addDoc(collection(db, "setup_data"), newData);
      }

      const querySnapshot = await getDocs(collection(db, "setup_data"));
      setSetupData(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
      setShowModal(false);
      setSelectedData(null);
    } catch (error) {
      console.error("Error saving data: ", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "eventBar") setEventBar(value);
    if (name === "contactUs") setContactUs(value);
    if (name === "partnersSponsors") setPartnersSponsors(value);
    if (name === "visitWebsite") setVisitWebsite(value);
    if (name === "telegram") setTelegram(value);
    if (name === "learnMore") setLearnMore(value);
    if (name === "paidBar") setPaidBar(value);                 // Handle input change
    if (name === "unpaidBar") setUnpaidBar(value);             // Handle input change
    if (name === "businessLeadPage") setBusinessLeadPage(value); // Handle input change
  };

  const handleToggleStatus = async (data) => {
    const newStatus = data.status === "Active" ? "Disabled" : "Active";
    const dataRef = doc(db, "setup_data", data.id);
    await updateDoc(dataRef, { status: newStatus });

    const querySnapshot = await getDocs(collection(db, "setup_data"));
    setSetupData(
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  };

  return (
    <div>
      <h3 className="text-center" style={{ backgroundColor: "grey" }}>
        Setup Data Manager
      </h3>
      <Table striped bordered hover>
        <tbody>
          {setupData.map((data) => (
            <React.Fragment key={data.id}>
              <tr>
                <td>
                  <strong style={{ fontSize: "1.5rem" }}>Name </strong>
                </td>
                <td>
                  <strong style={{ fontSize: "1.5rem" }}>Actions</strong>
                </td>
              </tr>
              <tr>
                <td><strong>Actions:</strong></td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleEdit(data)}
                    className="me-2"
                  >
                    Edit
                  </Button>
                  {data.status === "Disabled" && (
                    <Button
                      variant="success"
                      onClick={() => handleToggleStatus(data)}
                    >
                      Enable
                    </Button>
                  )}
                </td>
              </tr>

              <tr>
                <td><strong>Paid Bar:</strong></td>
                <td>{data.paidBar}</td>
              </tr>
              <tr>
                <td><strong>Un-paid Bar:</strong></td>
                <td>{data.UnpaidBar}</td>
              </tr>
              <tr>
                <td><strong>Business lead :</strong></td>
                <td>{data.businessLeadPage}</td>
              </tr>
              <tr>
                <td><strong>Event Bar:</strong></td>
                <td>{data.eventBar}</td>
              </tr>
              <tr>
                <td><strong>Contact Us:</strong></td>
                <td>{data.contactUs}</td>
              </tr>
              <tr>
                <td><strong>Partners/Sponsors:</strong></td>
                <td>{data.partnersSponsors}</td>
              </tr>
              <tr>
                <td><strong>Visit Website:</strong></td>
                <td>{data.visitWebsite}</td>
              </tr>
              <tr>
                <td><strong>Telegram:</strong></td>
                <td>{data.telegram}</td>
              </tr>
              <tr>
                <td><strong>Learn More:</strong></td>
                <td>{data.learnMore}</td>
              </tr>
            
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Setup Data" : "Add Setup Data"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSave}>
            <Form.Group className="mb-3" controlId="formEventBar">
              <Form.Label>Event Bar</Form.Label>
              <Form.Control
                type="text"
                name="eventBar"
                value={eventBar}
                onChange={handleInputChange}
                placeholder="Enter event bar"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formContactUs">
              <Form.Label>Contact Us</Form.Label>
              <Form.Control
                type="text"
                name="contactUs"
                value={contactUs}
                onChange={handleInputChange}
                placeholder="Enter contact us info"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPartnersSponsors">
              <Form.Label>Partners/Sponsors</Form.Label>
              <Form.Control
                type="text"
                name="partnersSponsors"
                value={partnersSponsors}
                onChange={handleInputChange}
                placeholder="Enter partners/sponsors"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formVisitWebsite">
              <Form.Label>Visit Website</Form.Label>
              <Form.Control
                type="text"
                name="visitWebsite"
                value={visitWebsite}
                onChange={handleInputChange}
                placeholder="Enter website URL"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTelegram">
              <Form.Label>Telegram</Form.Label>
              <Form.Control
                type="text"
                name="telegram"
                value={telegram}
                onChange={handleInputChange}
                placeholder="Enter Telegram handle or link"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLearnMore">
              <Form.Label>Learn More</Form.Label>
              <Form.Control
                type="text"
                name="learnMore"
                value={learnMore}
                onChange={handleInputChange}
                placeholder="Enter more info link"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPaidBar">
              <Form.Label>Paid Bar</Form.Label>
              <Form.Control
                type="text"
                name="paidBar"
                value={paidBar}
                onChange={handleInputChange}
                placeholder="Enter Paid Bar"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formUnpaidBar">
              <Form.Label>Unpaid Bar</Form.Label>
              <Form.Control
                type="text"
                name="unpaidBar"
                value={unpaidBar}
                onChange={handleInputChange}
                placeholder="Enter Unpaid Bar"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBusinessLeadPage">
              <Form.Label>Business Lead Page</Form.Label>
              <Form.Control
                type="text"
                name="businessLeadPage"
                value={businessLeadPage}
                onChange={handleInputChange}
                placeholder="Enter Business Lead Page"
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SetupDataManager;
